import PropTypes from "prop-types"
import React from "react"
import {Link} from "gatsby"

const Header = ({ siteMetadata }) => (
  <header id="" className="text-center hero">
    <h1><Link to="/">{siteMetadata.title}</Link></h1>
    <h3>{siteMetadata.subTitle}</h3>
  </header>
)

Header.propTypes = {
  siteMetadata: PropTypes.object,
}

Header.defaultProps = {
  siteMetadata: {
    title: ``,
    subTitle: ``,
  },
}

export default Header
